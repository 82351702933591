import React from "react"
import Layout from "src/component/layout/layout"
import { Seo } from "src/component/common/seo"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"

export default function Detail({ data }) {
    // console.log(data.microcmsNews.content)
    return (
        <>
            <Layout>
                <article className="p-detail">
                    <div className="p-detail__inner c-inner-primary">
                        <div className="p-detail__content l-oldPost">
                            <div className="news-date"><p>
                                {data.microcmsNews.display_date.split("T")[0].replaceAll("-", ".")}
                            </p></div>
                            <div className="p-detail__title c-title01">
                                <h1 className="p-detail__title__text c-title01__text">{data.microcmsNews.title}</h1>
                            </div>
                            <div className="p-detail__content__inner l-oldPost__inner">
                                <div className="c-single-body">
                                    {
                                        data.microcmsNews.content ? (
                                            data.microcmsNews.content.map((content) => (
                                                content.html ? (
                                                    <div
                                                        key={content.fieldId}
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${content.html}`,
                                                        }}
                                                    />
                                                ) : content.richEditor ? (
                                                    <div
                                                        key={content.fieldId}
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${content.richEditor}`,
                                                        }}
                                                    />
                                                ) : null
                                            ))
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </Layout>
        </>
    )
}

export function Head({ data }) {
    const location = useLocation()
    const thumbnail = data.microcmsNews.case_study_thumbnail
    const eyecatch = thumbnail ? thumbnail.url : data.site.siteMetadata.productionSiteUrl + "img/common/microdata_logo.png"
    const schema = {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": data.site.siteMetadata.productionSiteUrl + location.pathname,
        },
        "headline": data.microcmsNews.title,
        "datePublished": data.microcmsNews.display_date,
        "dateModified": data.microcmsNews.display_date,
        "publisher": {
            "@type": "Organization",
            "name": data.microcmsSetting.site_title,
            "url": data.site.siteMetadata.productionSiteUrl,
            "logo": {
                "@type": "ImageObject",
                "url": data.site.siteMetadata.productionSiteUrl + "img/common/microdata_logo.png"
            },
            "address": {
                "@type": "PostalAddress",
                "postalCode": "150-0043",
                "addressRegion": "東京都",
                "addressLocality": "渋谷区",
                "streetAddress": "道玄坂一丁目19番9号 第一暁ビル6階"
            }
        },
        "image": {
            "@type": "ImageObject",
            "url": eyecatch
        },
        "mainEntity": {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": data.site.siteMetadata.productionSiteUrl,
                        "name": "TOP"
                    }
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": data.site.siteMetadata.productionSiteUrl + "search",
                        "name": "記事一覧"
                    }
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": data.site.siteMetadata.productionSiteUrl.slice(0, -1) + location.pathname,
                        "name": data.microcmsNews.title
                    }
                }
            ]
        }
    };
    const schemaAsString = JSON.stringify(schema, null, 2);
    return (
        <Seo
            title={data.microcmsNews.title}
            description=""
            pathname=""
        >
            <script type="application/ld+json">{schemaAsString}</script>
        </Seo>
    )
}

export const query = graphql`
    query($id: String!) {
        site {
            siteMetadata {
                productionSiteUrl
            }
        }
        microcmsSetting {
            site_title
        }
        microcmsNews(id: { eq: $id }) {
            newsId
            display_date
            title
            case_study_thumbnail {
                url
            }
            content {
                fieldId
                richEditor
                html
            }
        }
    }
`